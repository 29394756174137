import { type IntlShape, defineMessages } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import type { ExtensionManifest, ExtensionHandler } from '@atlaskit/editor-common/extensions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { ExtensionPlugin } from '@atlaskit/editor-plugins/extension';

import { createQuickInsertModule } from '../utils';
import { unwrapMacroParams, wrapMacroParams } from '../../../editor-extensions/transformers';
import type {
	LegacyMacroManifest,
	RendererExtensionHandlers,
	ConfluencePageContext,
	OnCompleteCallbackFnType,
} from '../extensionTypes';
import type { MacroConfig } from '../../../extensions-common';
import {
	BraceSVG,
	BracketSVG,
	BulletSVG,
	CircleSVG,
	DiscSVG,
	DropdownIcon,
	NumberedSVG,
	PipeSVG,
	SquareSVG,
} from '../MacroIcon';
import { buildIconObject } from '../manifest-helpers';
const i18n = defineMessages({
	displayAs: {
		id: 'fabric-extension-lib.toc.displate-as',
		defaultMessage: 'Display as',
		description: 'Label for display as radio buttons',
	},
	separateSectionsBy: {
		id: 'fabric-extension-lib.toc.separate-sections',
		defaultMessage: 'Separate sections by',
		description: 'Label for separate sections by dropdown',
	},
	bulletStyle: {
		id: 'fabric-extension-lib.toc.bullet-style',
		defaultMessage: 'Bullet style',
		description: 'Label for bullet style dropdown',
	},
	includeHeadingLevelsFrom: {
		id: 'fabric-extension-lib.toc.include-heading-levels-from',
		defaultMessage: 'Include heading levels from',
		description: 'Label for include heading levels from checkbox',
	},
	includeHeadingLevelsTo: {
		id: 'fabric-extension-lib.toc.include-heading-levels-to',
		defaultMessage: 'to',
		description: 'Label for include heading levels to checkbox',
	},
	includeSectionNumbers: {
		id: 'fabric-extension-lib.toc.include-section-numbers',
		defaultMessage: 'Include section numbers',
		description: 'Label for include section nubmers checkbox',
	},
	indentHeadingsLabel: {
		id: 'fabric-extension-lib.toc.indent-headings.label',
		defaultMessage: 'Indent headings',
		description: 'Label for indent headings input box',
	},
	indentHeadingsDescription: {
		id: 'fabric-extension-lib.toc.indent-headings.description',
		defaultMessage: 'Enter a valid CSS unit value',
		description: 'Description for indent headings input box',
	},
	includeHeadingsWithLabel: {
		id: 'fabric-extension-lib.toc.include-headings-with.label',
		defaultMessage: 'Include headings with:',
		description: 'Label for including headings with input box',
	},
	excludeHeadingsWithLabel: {
		id: 'fabric-extension-lib.toc.exclude-headings-with.label',
		defaultMessage: 'Exclude headings with:',
		description: 'Label for excluding headings with input box',
	},
	headingsWithDescription: {
		id: 'fabric-extension-lib.toc.headings-with.description',
		defaultMessage: 'Enter one or more keywords separated by | , or any regular expression',
		description: "Description for 'include headings with' and 'exclude headings with' input boxes",
	},
	cssClassNameLabel: {
		id: 'fabric-extension-lib.toc.css-class-name.label',
		defaultMessage: 'CSS class name',
		description: 'Label for CSS class name input box',
	},
	cssClassNameDescription: {
		id: 'fabric-extension-lib.toc.css-class-name.description',
		defaultMessage:
			'Enter a valid CSS attribute to customize the <code>&lt;div&gt;</code> surrounding this table of contents',
		description: 'Description for CSS class name input box',
	},
	excludePDFExport: {
		id: 'fabric-extension-lib.toc.exclude-pdf-export',
		defaultMessage: 'Exclude PDF export',
		description: 'Label for excluding pdf export checkbox',
	},
	verticalList: {
		id: 'fabric-extension-lib.toc.vertical-list',
		defaultMessage: 'Vertical list',
		description: 'Label for vertical list radio selection',
	},
	horizontalList: {
		id: 'fabric-extension-lib.toc.horizontal-list',
		defaultMessage: 'Horizontal list',
		description: 'Label for horizontal list radio selection',
	},
	noneBulletStyle: {
		id: 'fabric-extension-lib.toc.none-style',
		defaultMessage: 'None',
		description: 'Label for none bullet style dropdown option',
	},
	bulletBulletStyle: {
		id: 'fabric-extension-lib.toc.bullet-bullet-style',
		defaultMessage: 'Bullet',
		description: 'Label for bullet bullet style dropdown option',
	},
	mixedBulletStyle: {
		id: 'fabric-extension-lib.toc.mixed-bullet-style',
		defaultMessage: 'Mixed',
		description: 'Label for mixed bullet style dropdown option',
	},
	circleBulletStyle: {
		id: 'fabric-extension-lib.toc.circle-bullet-style',
		defaultMessage: 'Circle',
		description: 'Label for circle bullet style dropdown option',
	},
	squareBulletStyle: {
		id: 'fabric-extension-lib.toc.square-bullet-style',
		defaultMessage: 'Square',
		description: 'Label for square bullet style dropdown option',
	},
	numberBulletStyle: {
		id: 'fabric-extension-lib.toc.number-bullet-style',
		defaultMessage: 'Number',
		description: 'Label for number bullet style dropdown option',
	},
	customBulletStyle: {
		id: 'fabric-extension-lib.toc.custom-bullet-style',
		defaultMessage: 'Custom',
		description: 'Label for custom bullet style',
	},
	bracketSeparator: {
		id: 'fabric-extension-lib.toc.bracket-separator',
		defaultMessage: 'Bracket',
		description: 'Label for bracket separator dropdown option',
	},
	braceSeparator: {
		id: 'fabric-extension-lib.toc.brace-separator',
		defaultMessage: 'Brace',
		description: 'Label for brace separator dropdown option',
	},
	pipeSeparator: {
		id: 'fabric-extension-lib.toc.pipe-separator',
		defaultMessage: 'Pipe',
		description: 'Label for pipe separator dropdown option',
	},
	basicTab: {
		id: 'fabric-extension-lib.toc.basic-tab',
		defaultMessage: 'Basic',
		description: 'Label for basic tab',
	},
	advancedTab: {
		id: 'fabric-extension-lib.toc.advanced-tab',
		defaultMessage: 'Advanced',
		description: 'Label for advanced tab',
	},
	tocPlaceholder: {
		id: 'fabric-extension-lib.toc.placeholder',
		defaultMessage:
			'A table of contents based on the headings of this page will appear here once it has been published.',
		description:
			'Placeholder for Table of Contents macro in edit mode. It will appear when the page is published.',
	},
	description: {
		id: 'fabric-extension-lib.toc.description',
		defaultMessage: 'Create a table of contents based on your page’s headings',
		description: 'Description for Table of Contents macro right rail config panel',
	},
	title: {
		id: 'fabric-extension-lib.toc.title',
		defaultMessage: 'Table of contents',
		description: 'Title for Table of Contents macro right rail config panel.',
	},
});

const numbers = defineMessages({
	one: {
		id: 'fabric-extension-lib.one',
		defaultMessage: '1',
		description: 'Label for number one',
	},
	two: {
		id: 'fabric-extension-lib.two',
		defaultMessage: '2',
		description: 'Label for number two',
	},
	three: {
		id: 'fabric-extension-lib.three',
		defaultMessage: '3',
		description: 'Label for number three',
	},
	four: {
		id: 'fabric-extension-lib.four',
		defaultMessage: '4',
		description: 'Label for number four',
	},
	five: {
		id: 'fabric-extension-lib.five',
		defaultMessage: '5',
		description: 'Label for number five',
	},
	six: {
		id: 'fabric-extension-lib.six',
		defaultMessage: '6',
		description: 'Label for number six',
	},
});

const convertType = ({ acc, value }) => {
	switch (value) {
		case 'flat':
			acc['display-as'] = 'horizontal';
			break;

		case 'list':
			acc['display-as'] = 'vertical';
			break;

		case 'horizontal':
			acc['type'] = 'flat';
			break;

		case 'vertical':
			acc['type'] = 'list';
			break;
	}
};

const convertStyle = ({ transformType, acc, value }) => {
	const key = transformType === 'transformBefore' ? 'bullet-styles' : 'style';

	switch (value) {
		/*
    If the value coming in is "mixed," it implies the transformAfter case,
    i.e., the user selected "mixed" in the config panel. In this case, we need to
    set the `style` property to "default," as that's what's actually stored on the
    backend. The `style` property is also what's subsequently used in determining the
    CSS list style of the macro. Conversely, if the value is "default," it implies
    the transformBefore case, so we need to set the `bullet-styles` property to
    "mixed" to indicate that the list style should show mixed in the config panel.
    */
		case 'mixed':
			acc['style'] = 'default';
			break;

		case 'default':
			acc['bullet-styles'] = 'mixed';
			break;

		case 'disc':
			acc['bullet-styles'] = 'bullet';
			break;

		case 'bullet':
			acc['style'] = 'disc';
			break;

		case 'circle':
			acc[key] = 'circle';
			break;

		case 'square':
			acc[key] = 'square';
			break;

		case 'none':
			acc[key] = 'none';
			break;

		case 'decimal':
			acc['bullet-styles'] = 'number';
			break;

		case 'number':
			acc['style'] = 'decimal';
			break;
	}
};

const convertSeparator = ({ transformType, acc, value }) => {
	const key = transformType === 'transformBefore' ? 'separate-sections-by' : 'separator';

	switch (value) {
		case 'brackets':
			acc[key] = 'brackets';
			break;

		case 'braces':
			acc[key] = 'braces';
			break;

		case 'pipe':
			acc[key] = 'pipe';
			break;
	}
};

type TableOfContentsProps = {
	macro: LegacyMacroManifest;
	pageContext: ConfluencePageContext;
	extensionHandlers: RendererExtensionHandlers;
	openMacroBrowserForInitialConfiguration: Function;
	macroBrowserConfig?: MacroConfig;
	editorActions?: EditorActions;
	editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	intl: IntlShape;
	onCompleteCallback?: OnCompleteCallbackFnType;
	isLivePage?: boolean;
};

export const transformBefore = (parameters) => {
	const unwrappedParams = unwrapMacroParams(parameters.macroParams);

	return Object.keys(unwrappedParams).reduce((acc, key) => {
		const value = unwrappedParams[key];

		switch (key) {
			case 'type':
				convertType({ acc, value });
				break;

			case 'style':
				convertStyle({ transformType: 'transformBefore', acc, value });
				break;

			case 'separator':
				convertSeparator({ transformType: 'transformBefore', acc, value });
				break;

			case 'minLevel':
				acc['include-heading-levels-from'] = value;

				break;

			case 'maxLevel':
				acc['include-heading-levels-to'] = value;

				break;

			case 'outline':
				acc['include-section-numbers'] = value === 'true';

				break;

			case 'indent':
				acc['indent-headings-advanced'] = value;

				break;

			case 'include':
				acc['include-headings-advanced'] = value;

				break;

			case 'exclude':
				acc['exclude-headings-advanced'] = value;

				break;

			case 'class':
				acc['css-class-name-advanced'] = value;

				break;

			case 'printable':
				// "printable" technically means "include PDF export", but since changing the terminology
				// to "exclude PDF export" we need to inverse the evaluation
				acc['exclude-pdf-export-advanced'] = value !== 'true';

				break;
		}

		return acc;
	}, {});
};

export const transformAfter = async (parameters) => {
	const transformedParams = Object.keys(parameters).reduce((acc, key) => {
		const value = parameters[key];
		switch (key) {
			case 'display-as':
				convertType({ acc, value });
				break;

			case 'bullet-styles':
				convertStyle({ transformType: 'transformAfter', acc, value });
				break;

			case 'separate-sections-by':
				convertSeparator({ transformType: 'transformAfter', acc, value });
				break;

			case 'include-heading-levels-from':
				acc['minLevel'] = value;

				break;

			case 'include-heading-levels-to':
				acc['maxLevel'] = value;

				break;

			case 'include-section-numbers':
				acc['outline'] = JSON.stringify(value);

				break;

			case 'indent-headings-advanced':
				acc['indent'] = value;

				break;

			case 'include-headings-advanced':
				acc['include'] = value;

				break;

			case 'exclude-headings-advanced':
				acc['exclude'] = value;

				break;

			case 'css-class-name-advanced':
				acc['class'] = value;

				break;

			case 'exclude-pdf-export-advanced':
				acc['printable'] = value === false ? 'true' : 'false';

				break;
		}

		return acc;
	}, {});

	const wrappedParams = wrapMacroParams(transformedParams);
	return { macroParams: wrappedParams };
};

export const tableOfContentsExtension = ({
	macro,
	pageContext,
	extensionHandlers,
	openMacroBrowserForInitialConfiguration,
	macroBrowserConfig,
	editorAPI,
	editorActions,
	createAnalyticsEvent,
	intl,
	onCompleteCallback,
	isLivePage,
}: TableOfContentsProps) => {
	const title = intl.formatMessage(i18n.title);

	const description = intl.formatMessage(i18n.description);

	macro.title = title;
	macro.description = description;

	return {
		type: 'com.atlassian.confluence.macro.core',
		key: 'toc',
		title,
		description,
		icons: buildIconObject(macro),
		documentationUrl: 'https://confluence.atlassian.com/display/ConfCloud/Table+of+Contents+Macro',
		modules: {
			quickInsert: createQuickInsertModule(
				macro,
				pageContext,
				openMacroBrowserForInitialConfiguration,
				editorAPI,
				intl,
				macroBrowserConfig,
				editorActions,
				createAnalyticsEvent,
				onCompleteCallback,
				isLivePage,
			),
			nodes: {
				default: {
					type: 'extension',
					render: () => {
						const extensionHandler = extensionHandlers['com.atlassian.confluence.macro.core'];

						return Promise.resolve((extension) => {
							if (typeof extensionHandler === 'function') {
								return (extensionHandler as ExtensionHandler<any>)(extension.node, {
									references: extension.references,
								});
							}
							return extensionHandler.render(extension.node, {
								references: extension.references,
							});
						});
					},
					update: (_, actions) => {
						return new Promise(() => {
							actions!.editInContextPanel(transformBefore, transformAfter);
						});
					},
					getFieldsDefinition: (params) => {
						const typeValue = params.macroParams['type']?.value;
						const includeHeadingsOptions = [
							{
								label: intl.formatMessage(numbers.one),
								value: '1',
							},
							{
								label: intl.formatMessage(numbers.two),
								value: '2',
							},
							{
								label: intl.formatMessage(numbers.three),
								value: '3',
							},
							{
								label: intl.formatMessage(numbers.four),
								value: '4',
							},
							{
								label: intl.formatMessage(numbers.five),
								value: '5',
							},
							{
								label: intl.formatMessage(numbers.six),
								value: '6',
							},
						];

						return Promise.resolve([
							{
								type: 'tab-group',
								name: 'tabGroup',
								label: 'ToC Tab Group',
								defaultTab: 'basic',
								fields: [
									{
										type: 'tab',
										label: intl.formatMessage(i18n.basicTab),
										name: 'basic',
										fields: [
											{
												name: 'display-as',
												type: 'enum',
												style: 'radio',
												label: intl.formatMessage(i18n.displayAs),
												isMultiple: false,
												defaultValue: 'vertical',
												items: [
													{
														label: intl.formatMessage(i18n.verticalList),
														value: 'vertical',
													},
													{
														label: intl.formatMessage(i18n.horizontalList),
														value: 'horizontal',
													},
												],
											},
											...(typeValue === 'flat'
												? [
														{
															name: 'separate-sections-by',
															label: intl.formatMessage(i18n.separateSectionsBy),
															type: 'enum',
															style: 'select',
															isMultiple: false,
															defaultValue: 'brackets',
															items: [
																{
																	label: intl.formatMessage(i18n.bracketSeparator),
																	value: 'brackets',
																	icon: DropdownIcon({
																		icon: BracketSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.braceSeparator),
																	value: 'braces',
																	icon: DropdownIcon({
																		icon: BraceSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.pipeSeparator),
																	value: 'pipe',
																	icon: DropdownIcon({
																		icon: PipeSVG,
																	}),
																},
															],
														},
													]
												: [
														{
															name: 'bullet-styles',
															label: intl.formatMessage(i18n.bulletStyle),
															type: 'enum',
															style: 'select',
															isMultiple: false,
															defaultValue: !params.macroParams['style'] && 'mixed',
															placeholder:
																!['none', 'mixed', 'bullet', 'circle', 'square', 'number'].includes(
																	params.macroParams['style'],
																) && intl.formatMessage(i18n.customBulletStyle),
															items: [
																{
																	label: intl.formatMessage(i18n.noneBulletStyle),
																	value: 'none',
																	icon: null,
																},
																{
																	label: intl.formatMessage(i18n.mixedBulletStyle),
																	value: 'mixed',
																	icon: DropdownIcon({
																		icon: DiscSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.bulletBulletStyle),
																	value: 'bullet',
																	icon: DropdownIcon({
																		icon: BulletSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.circleBulletStyle),
																	value: 'circle',
																	icon: DropdownIcon({
																		icon: CircleSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.squareBulletStyle),
																	value: 'square',
																	icon: DropdownIcon({
																		icon: SquareSVG,
																	}),
																},
																{
																	label: intl.formatMessage(i18n.numberBulletStyle),
																	value: 'number',
																	icon: DropdownIcon({
																		icon: NumberedSVG,
																	}),
																},
															],
														},
													]),

											{
												name: 'include-heading-levels-from',
												label: intl.formatMessage(i18n.includeHeadingLevelsFrom),
												type: 'enum',
												style: 'select',
												isMultiple: false,
												defaultValue: '1',
												items: includeHeadingsOptions,
											},
											{
												name: 'include-heading-levels-to',
												label: intl.formatMessage(i18n.includeHeadingLevelsTo),
												type: 'enum',
												style: 'select',
												isMultiple: false,
												defaultValue: '6',
												items: includeHeadingsOptions,
											},
											{
												name: 'include-section-numbers',
												type: 'boolean',
												style: 'checkbox',
												label: intl.formatMessage(i18n.includeSectionNumbers),
												defaultValue: false,
											},
										],
									},
									{
										type: 'tab',
										label: intl.formatMessage(i18n.advancedTab),
										name: 'advanced',
										fields: [
											{
												name: 'indent-headings-advanced',
												label: intl.formatMessage(i18n.indentHeadingsLabel),
												isRequired: false,
												description: intl.formatMessage(i18n.indentHeadingsDescription),
												type: 'string',
											},
											{
												name: 'include-headings-advanced',
												label: intl.formatMessage(i18n.includeHeadingsWithLabel),
												isRequired: false,
												description: intl.formatMessage(i18n.headingsWithDescription),
												type: 'string',
											},
											{
												name: 'exclude-headings-advanced',
												label: intl.formatMessage(i18n.excludeHeadingsWithLabel),
												isRequired: false,
												description: intl.formatMessage(i18n.headingsWithDescription),
												type: 'string',
											},
											{
												name: 'css-class-name-advanced',
												label: intl.formatMessage(i18n.cssClassNameLabel),
												isRequired: false,
												description: intl.formatMessage(i18n.cssClassNameDescription),
												type: 'string',
											},
											{
												name: 'exclude-pdf-export-advanced',
												label: intl.formatMessage(i18n.excludePDFExport),
												defaultValue: false,
												style: 'checkbox',
												type: 'boolean',
											},
										],
									},
								],
							},
						]);
					},
				},
			},
		},
	} as ExtensionManifest;
};
