import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import React, { memo } from 'react';

import type { ADNode } from '@atlaskit/editor-common/validator';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { extensionToADF } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';
import {
	MacroExperienceFailure,
	MacroExperienceSuccess,
	getExperienceName,
	getMacroAttributesFromADFNode,
} from '@confluence/macro-tracker';

import {
	FlatStyleTocItemContainer,
	ListStyleTocItemContainer,
	ListStyleTocLevelContainer,
} from './TableOfContentsElementStructure';
import { handleTocNode } from './handleADF';
import { generateTocTreeStructure } from './generateTocTreeStructure';
import { TableOfContentsComponent } from './TableOfContentsComponent';

export const nonPrintableWrapperTestId = 'non-printable-wrapper';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NonPrintableWrapper = styled.div({
	overflow: 'auto',
	'@media print': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'ul ul:not(:first-child), ol ul:not(:first-child)': {
		marginTop: '0px',
	},
});

export const printableWrapperTestId = 'printable-wrapper';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrintableWrapper = styled.div({
	overflow: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'ul ul:not(:first-child), ol ul:not(:first-child)': {
		marginTop: '0px',
	},
});

export interface ElementFactoryInterface {
	createTocLevelContainer: Function;
	createTocItemContainer: Function;
	decorateToc?: Function;
}

export interface SsrElementContentInterface {
	text: string;
	type: string;
	innerHTML?: string;
}

export interface SsrElementInterface {
	type: string;
	id?: string;
	attrs: {
		level: number;
		startPos?: string;
	};
	content: SsrElementContentInterface[];
}

export interface TableOfContentsInterface {
	adf: ADNode | string | null;
	cloudId: string;
	userId: string;
	macroDefaultProps: ExtensionHandlerProps;
	isInEditMode?: boolean;
}

export const EmptyTocItem = 'toc-empty-item';

export function getSeparators(separator: string) {
	let separatorList: string[] = [];
	switch (separator) {
		case 'braces':
			separatorList = ['{ ', ' } { ', ' }'];
			break;
		case 'pipe':
			separatorList = ['', ' | ', ''];
			break;
		case 'parens':
			separatorList = ['( ', ' ) ( ', ' )'];
			break;
		case '':
		case 'brackets':
			// brackets in the ADF is an empty string
			separatorList = ['[ ', ' ] [ ', ' ]'];
			break;
		default:
			// Match separator usage for custom separators
			separatorList = ['', separator, ''];
			break;
	}
	return separatorList;
}

const TableOfContentsContainer: FC<TableOfContentsInterface> = ({
	cloudId,
	userId,
	adf,
	macroDefaultProps,
	isInEditMode = false,
}) => {
	const { node, mode, contentId, extensionKey } = macroDefaultProps;
	const macroNode = extensionToADF(node);
	const tocOptions = handleTocNode(macroNode, adf);
	const attributes = getMacroAttributesFromADFNode(node);
	const name = getExperienceName(mode, node);

	let ElementFactory: ElementFactoryInterface;
	let elementsArray: object[] | null;
	if (tocOptions.structure === 'flat') {
		ElementFactory = flatTocElementFactory();
	} else {
		ElementFactory = listTocElementFactory();
	}

	try {
		elementsArray = generateTocTreeStructure(tocOptions.headerelements, [], cloudId, userId);
	} catch (error) {
		return (
			<MacroExperienceFailure
				name={name}
				contentId={contentId}
				extensionKey={extensionKey}
				mode={mode}
				error={error || new Error(`${extensionKey} failed to render`)}
				attributes={attributes}
				source="TableOfContents"
			/>
		);
	}

	const separators: any = getSeparators(tocOptions.separators || '');
	const initialSeparatorRef = { current: true };

	const TocContent = () => {
		const flatSeparators =
			tocOptions.structure === 'flat'
				? {
						'data-preseparator': separators[0],
						'data-midseparator': separators[1],
						'data-postseparator': separators[2],
					}
				: {};
		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={tocOptions.className}
				data-numberedoutline={tocOptions.outline}
				data-cssliststyle={tocOptions.cssliststyle}
				data-csslistindent={tocOptions.csslistindent}
				data-headerelements={tocOptions.headerRange}
				data-hasbody={tocOptions.hasbody}
				data-macro-name={tocOptions.name}
				data-macro-id={tocOptions.macroId}
				data-structure={tocOptions.structure}
				{...flatSeparators}
			>
				<TableOfContentsComponent
					elementsArray={elementsArray}
					elementFactory={ElementFactory}
					config={tocOptions}
					separators={separators}
					initialSeparatorRef={initialSeparatorRef}
					isInEditMode={isInEditMode}
				/>
				<MacroExperienceSuccess
					name={name}
					contentId={contentId}
					extensionKey={extensionKey}
					mode={mode}
				/>
			</div>
		);
	};

	return tocOptions.printable === 'false' ? (
		<NonPrintableWrapper
			title="Macro (toc)"
			data-fabric-macro={tocOptions.macroId}
			data-macro-body
			data-macro-parameters={JSON.stringify(macroNode?.attrs?.parameters?.macroParams)}
			data-testid={nonPrintableWrapperTestId}
			data-vc="non-printable-toc-wrapper"
		>
			<TocContent />
		</NonPrintableWrapper>
	) : (
		<PrintableWrapper
			title="Macro (toc)"
			data-fabric-macro={tocOptions.macroId}
			data-macro-body
			data-macro-parameters={JSON.stringify(macroNode?.attrs?.parameters?.macroParams)}
			data-testid={printableWrapperTestId}
			data-vc="printable-toc-wrapper"
			data-ssr-placeholder="printable-toc-wrapper"
			data-ssr-placeholder-replace="printable-toc-wrapper"
		>
			<TocContent />
		</PrintableWrapper>
	);
};

/**
 * Creates HTML elements for use in building a flat-style TOC
 */
function flatTocElementFactory() {
	return {
		createTocLevelContainer() {
			return FlatStyleTocItemContainer;
		},
		createTocItemContainer() {
			return FlatStyleTocItemContainer;
		},
		decorateToc() {
			return true;
		},
	};
}

/**
 * Creates HTML elements for use in building a list-style TOC
 */
function listTocElementFactory() {
	return {
		createTocLevelContainer() {
			return ListStyleTocLevelContainer;
		},
		createTocItemContainer() {
			return ListStyleTocItemContainer;
		},
	};
}

export const TableOfContents = memo(
	withErrorBoundary({
		attribution: Attribution.BACKBONE,
	})(TableOfContentsContainer),
	isEqual,
);
