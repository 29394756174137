import cloneDeep from 'lodash/cloneDeep';

import type { ADFEntity } from '@atlaskit/adf-utils/types';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADNode } from '@atlaskit/editor-common/validator';

import { getLogger } from '@confluence/logger';
import { getHeadingId } from '@confluence/editor-heading-links';

const logger = getLogger('table-of-contents');
/**
 *
 * @param macroNode
 * @param adf - optional
 *
 * Used to handle options provided by adf to create the TOC
 */
export const handleTocNode = (macroNode: ADNode, adf: ADNode | string | null) => {
	const minConfig = Math.max(
		1,
		parseInt(macroNode?.attrs?.parameters?.macroParams?.minLevel?.value) || 1,
	);
	const maxConfig = Math.min(
		6,
		parseInt(macroNode?.attrs?.parameters?.macroParams?.maxLevel?.value) || 6,
	);
	const macroParams = macroNode?.attrs?.parameters?.macroParams;

	const headingElements: ADFEntity[] = [];
	const includeheaderregex = macroParams?.include?.value;
	const excludeheaderregex = macroParams?.exclude?.value;

	// Helper function to go through heading content
	// and test regular expression against texts from content
	function filterHeadingWithRegex(node: ADFEntity) {
		if (!node?.content) {
			return false;
		}
		if (!includeheaderregex && !excludeheaderregex) {
			return true;
		}
		try {
			let includeRegex;
			if (includeheaderregex) {
				includeRegex = new RegExp(includeheaderregex);
			}
			let excludeRegex;
			if (excludeheaderregex) {
				excludeRegex = new RegExp(excludeheaderregex);
			}
			let fullHeaderString = '';
			for (const el of node?.content) {
				fullHeaderString +=
					el?.text || el?.attrs?.text || el?.attrs?.__confluenceMetadata?.contentTitle || '';
			}
			if (includeRegex && !includeRegex.test(fullHeaderString)) {
				return false;
			}
			if (excludeRegex && excludeRegex.test(fullHeaderString)) {
				return false;
			}
		} catch (error) {
			logger.error`@@@@@ Failed to handle regular expression - ${error}`;
		}

		return true;
	}
	// Hold a list of headers to look for duplicates
	// duplicated headers require an additional index/key which appends the link
	const headingsIds = new Set<string>();

	// NOTE: ADFNode and ADFEntity share the same shape but ADFEntity allows for more keys
	let adfObject = adf as ADFEntity;
	if (typeof adf === 'string') {
		try {
			adfObject = JSON.parse(adf);
		} catch (err) {
			// Swallow the error as ADF format error handling is expected to be done earlier,
			// in the component that renders the entire content
		}
	}

	// Traverse through ADF and filter out nodes that doesn't belong in the TOC
	// Options includes (minLevel, maxLevel, includeheaderregex, excludeheaderrregex)
	traverse(adfObject || {}, {
		heading: (node) => {
			if (filterHeadingWithRegex(node)) {
				// init deep-copy node
				const clone = cloneDeep(node);
				clone.attrs!.id = getHeadingId(clone, headingsIds);
				if (node?.attrs?.level >= minConfig && node?.attrs?.level <= maxConfig) {
					headingElements.push(clone);
				}
			}
		},
	});

	return {
		className: `${macroParams?.class?.value || 'macro-core'} toc-macro conf-macro output-block`,
		cssliststyle: macroParams?.style?.value,
		csslistindent: macroParams?.indent?.value,
		separators: macroParams?.separator?.value,
		headerelements: headingElements,
		headerRange: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].slice(minConfig - 1, maxConfig),
		hasbody: macroNode?.attrs.bodyType === 'none' ? 'false' : 'true',
		name: macroNode?.attrs?.extensionKey,
		structure: macroParams?.type?.value,
		outline: macroParams?.outline?.value,
		printable: macroParams?.printable?.value || 'true',
		macroId: macroNode?.attrs?.parameters?.macroMetadata?.macroId?.value,
	};
};
