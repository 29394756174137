import { LoadableAfterPaint } from '@confluence/loadable';
// eslint-disable-next-line no-restricted-imports
import type { LoadableType } from '@confluence/loadable/src/types';

import type { DateInterface } from './DateComponent';

export const DateLoader: LoadableType<DateInterface> = LoadableAfterPaint({
	name: 'DateLoader',
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DateComponent" */ './DateComponent'))
			.DateComponent,
});

export const EmojiLoader = LoadableAfterPaint({
	name: 'EmojiLoader',
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-EmojiComponent" */ './EmojiComponent'))
			.EmojiComponent,
});

export const MentionLoader = LoadableAfterPaint({
	name: 'EmojiLoader',
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-MentionComponent" */ './MentionComponent'))
			.MentionComponent,
});

export const StatusLoader = LoadableAfterPaint({
	name: 'StatusLoader',
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-StatusComponent" */ './StatusComponent'))
			.StatusComponent,
});
