import type { ReactElement } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { scrollToHeader } from './scrollToHeader';

// This file contains all of the templates to create
// a list/flat TOC, ElementFactory determines this

export interface KeyableInterface {
	[key: string]: any;
}

export interface ElementsInterface {
	innerContent?: string;
	link?: string;
	outline?: string;
	children?: ElementsInterface[] | null;
}

export interface OptionsInterface {
	options: KeyableInterface;
}

export interface ElementInterface {
	children: ReactElement;
}

export interface ListStyleContainerInterface {
	children: ReactElement;
	prop: KeyableInterface;
	link?: string;
}

export interface TocItemBodyProps {
	outline: any;
	linkHref: string;
	linkText: string;
}

interface TocSpanInterface {
	display: string;
	fontSize: string;
	outline?: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TocSpan = styled.span<{ prop: TocSpanInterface }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ prop }) => (prop.outline === 'true' || prop.display === 'true' ? 'inline' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ prop }) => prop.fontSize,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemAnchor = styled.a<{ fontSize: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ fontSize }) => fontSize,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListItem = styled.li<{ listStyle: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	listStyle: ({ listStyle }) => listStyle,
});

export const tocItemBodyTestId = 'toc-item-body';
/**
 * Renders a single toc-item-body element
 * @param element consists of information to fill the TOC body
 * @param outline flag to determine if outlining TOC body elements is required
 */
export const TocItemBody = (element: ElementsInterface, outline: string) => {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<span className="toc-item-body" data-outline={element.outline}>
			<TocSpan prop={{ display: '', fontSize: '16px', outline }}>{element.outline} </TocSpan>
			<ItemAnchor
				href={element.link}
				onClick={() => scrollToHeader(element.link)}
				data-testid={tocItemBodyTestId}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="toc-link"
				fontSize="16px"
			>
				{element.innerContent}
			</ItemAnchor>
		</span>
	);
};

export const listStyleTocLevelContainerTestId = 'list-style-toc-level-container';
export const ListStyleTocLevelContainer = ({ children, prop }: ListStyleContainerInterface) => {
	// styled components did not style list indentation with padding-left properly
	return (
		<ul
			data-testid={listStyleTocLevelContainerTestId}
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
			style={{ paddingLeft: prop?.csslistindent }}
		>
			{children}
		</ul>
	);
};

export const listStyleTocItemContainerTestId = 'list-style-toc-item-container';
export const ListStyleTocItemContainer = ({
	children,
	prop,
	link,
}: ListStyleContainerInterface) => {
	let listStyle;
	if (link === 'toc-empty-item') {
		listStyle = 'none';
	} else {
		listStyle = prop?.cssliststyle ?? 'default';
	}
	return (
		<ListItem data-testid={listStyleTocItemContainerTestId} listStyle={listStyle}>
			{children}
		</ListItem>
	);
};

export const flatStyleTocItemContainerTestId = 'flat-style-toc-item-container';
export const FlatStyleTocItemContainer = ({ children }: ElementInterface) => {
	return (
		<span
			data-testid={flatStyleTocItemContainerTestId}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="toc-item-container"
		>
			{children}
		</span>
	);
};

export const FlatStyleTocSeparator = (prop: any) => {
	return (
		<TocSpan
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="toc-separator"
			prop={{ display: 'true', fontSize: '16px' }}
		>
			{prop.separator}
		</TocSpan>
	);
};
