import type { ReactElement } from 'react';
import React, { Fragment } from 'react';

import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { MiniRenderer } from '@confluence/mini-renderer';

import type { SsrElementInterface } from './TableOfContents';
import { EmptyTocItem } from './TableOfContents';

/**
 * Builds a table of contents based on the supplied list of elements.
 *
 * This is necessary for both List and Flat style
 * List TOC requires the TOC to have it's data shaped into a tree struct
 * Flat TOC may require the outline output
 *
 * @param elementsArray The HTML elements to derive the TOC from. Each element is expected to have been pre-populated
 * @param parentOutline The outline inherited from the parent TOC level
 */
export function generateTocTreeStructure(
	elementsArray: ADFEntity[],
	parentOutline: string[] = [],
	cloudId,
	userId,
) {
	if (elementsArray.length === 0) {
		return null;
	}

	const highestElementPrecedence = elementsArray
		.map((el) => el?.attrs?.level)
		.reduce(function (a: number, b: number) {
			return Math.min(a, b);
		});

	// Make sure the first element in the list matches the highest precedence level
	if (elementsArray[0]?.attrs?.level !== highestElementPrecedence) {
		elementsArray.unshift({
			type: 'toc',
			attrs: {
				level: highestElementPrecedence,
			},
			content: [
				{
					text: EmptyTocItem,
					type: 'string',
					innerHTML: '',
				},
			],
		});
	}
	const currentList: object[] = [];

	// This buffers up "sub-headers" of the current TOC level until they need to be written out as a sub-toc
	interface SubListBufferInterface {
		subElements: SsrElementInterface[];
		currentItem: {
			innerContent: ReactElement | null;
			link: string;
			outline: string[];
			children: object[] | null;
		};
		outline?: string[];
		flush: Function;
		add: Function;
		resetItem: Function;
	}

	const subListBuffer: SubListBufferInterface = {
		subElements: [],
		currentItem: { innerContent: null, link: '', outline: [], children: null },
		outline: undefined,

		flush() {
			// eslint-disable-next-line react/prefer-stateless-function
			if (subListBuffer.subElements.length > 0 && subListBuffer.currentItem) {
				subListBuffer.currentItem.children = generateTocTreeStructure(
					subListBuffer.subElements,
					subListBuffer.outline,
					cloudId,
					userId,
				);
				subListBuffer.subElements = [];
			}
		},

		add(element: SsrElementInterface) {
			subListBuffer.subElements.push(element);
		},

		resetItem(currentOutlineItem: string) {
			subListBuffer.outline = (parentOutline || []).slice(0);
			subListBuffer.outline.push(currentOutlineItem);
			subListBuffer.currentItem = {
				innerContent: null,
				link: '',
				outline: [],
				children: null,
			};
			currentList.push(subListBuffer.currentItem);
			return subListBuffer.currentItem;
		},
	};

	let outlineCounter = 0;

	elementsArray.forEach(function (element) {
		if (element?.attrs?.level === highestElementPrecedence) {
			outlineCounter++;
			subListBuffer.flush();

			subListBuffer.resetItem(outlineCounter);
			if (element.content?.[0]?.text !== EmptyTocItem && subListBuffer.currentItem) {
				const style = createSSRTocItemBody(
					element,
					cloudId,
					userId,
					subListBuffer.outline?.join('.'),
				);
				subListBuffer.currentItem.innerContent = style.linkText;
				subListBuffer.currentItem.link = style.linkHref;
				subListBuffer.currentItem.outline = style.outline ? [style.outline] : [];
			} else {
				subListBuffer.currentItem.innerContent = <Fragment />;
				subListBuffer.currentItem.link = EmptyTocItem;
			}
		} else {
			subListBuffer.add(element);
		}
	});

	subListBuffer.flush();
	return currentList;
}

/**
 *
 * @param obj
 * @param outline
 * Creates an HTML element representing a single item in the TOC, including outline level and a link.
 */
function createSSRTocItemBody(obj: ADFEntity, cloudId: string, userId: string, outline?: string) {
	return {
		outline,
		linkHref: `#${encodeURIComponent(obj?.attrs?.id)}`,
		linkText: getTextsFromElements(obj.content || [], cloudId, userId),
	};
}

/*
 ** Accumulates the texts from elements into a single header
 */
function getTextsFromElements(
	contentArray: (ADFEntity | undefined)[],
	cloudId: string,
	userId: string,
) {
	if (!contentArray) {
		return null;
	}

	// Usage for mini-renderer
	// This is necessary as TOC runs into a race condition if we simply inject rendered results from the DOM
	// Because of async components that contain headers, ADF may not have sufficient content to render everything
	// We need to decide whether or not it is necessary to render injected output thereafter
	return <MiniRenderer adfEntities={contentArray} cloudId={cloudId} userId={userId} />;
}
