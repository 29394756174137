import type { Node } from 'prosemirror-model';

import type { ADNode } from '@atlaskit/editor-common/validator';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
/**
 *
 * @param node
 *
 * Generates header links and keeps track of duplicate links, appending it with .`#ofappearances`
 * Ideally the node should give us the id info.
 * Native dom functions wouldn't work because we are deferring injection of macros.
 *
 */
export function getHeadingId(node: ADFEntity, headingIds: Set<string>) {
	if (!node?.content?.length) {
		return;
	}

	// We are not use node.textContent here, because we would like to handle cases where
	// headings only contain inline blocks like emoji, status and date.
	const nodeContent = node.content
		.reduce((acc: string, currentNode: any) => acc.concat(getText(currentNode) || ''), '')
		.trim()
		.replace(/\s/g, '-');

	if (!nodeContent) {
		return;
	}

	return getUniqueHeadingId(nodeContent, headingIds);
}

const getText = (node: ADFEntity): string => {
	return (
		node.text ||
		(node.attrs && (node.attrs.text || node.attrs.shortName)) ||
		`[${typeof node.type === 'string' ? node.type : ''}]`
	);
};

function getUniqueHeadingId(baseId: string, headingIds: Set<string>, counter = 0): string {
	if (counter === 0 && !headingIds.has(baseId)) {
		headingIds.add(baseId);
		return baseId;
	} else if (counter !== 0) {
		const headingId = `${baseId}.${counter}`;
		if (!headingIds.has(headingId)) {
			headingIds.add(headingId);
			return headingId;
		}
	}

	return getUniqueHeadingId(baseId, headingIds, counter + 1);
}

const HEADINGS = new Set(['H1', 'H2', 'H3', 'H4', 'H5', 'H6']);
function* getHeadingIds(): Generator<[string, Element, Node]> {
	const editorElement = document.getElementById('ak-editor-textarea');
	const children = editorElement?.getElementsByTagName('*') ?? [];
	const headingIds: Set<string> = new Set();
	const editorTransformer = new JSONTransformer();

	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		if (HEADINGS.has(child.tagName)) {
			const pmNode = (child as any).pmViewDesc?.node;

			if (!pmNode) {
				continue;
			}

			// Copied from TOC
			const adf = editorTransformer.encode(pmNode) as ADNode;
			let adfObject = adf as ADFEntity;
			if (typeof adf === 'string') {
				try {
					adfObject = JSON.parse(adf);
				} catch (err) {
					// Swallow the error as ADF format error handling is expected to be done earlier,
					// in the component that renders the entire content
				}
			}

			const headingId = getHeadingId(adfObject, headingIds);
			if (!headingId) {
				continue;
			}

			yield [headingId, child, pmNode];
		}
	}
}

export const getHeadingIdsToElements = (): Map<string, Element> => {
	const headingIdToElement: Map<string, Element> = new Map();

	for (const [headingId, child, _] of getHeadingIds()) {
		headingIdToElement.set(headingId, child);
		// A bit inefficient, but there's sometimes a discrepancy between the hashed ID and the generated ID. The least impactful solution is to just add both.
		headingIdToElement.set(encodeURIComponent(headingId), child);
	}

	return headingIdToElement;
};

export const getHeadingIdForNode = (node: Node): string | undefined => {
	for (const [headingId, _, pmNode] of getHeadingIds()) {
		if (node === pmNode) {
			return headingId;
		}
	}

	return undefined;
};
